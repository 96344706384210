import React from "react";

const Middle = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area">
        <div className="container">
          <div className="tp-cta-wrapper pt-105 pb-120">
            <div className="row align-items-center justify-content-evenly">
              <div className="col-lg-9">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    Why choose us?
                  </span>
                  <h2 className="tp-section-title">
We offer customized, end-to-end solutions that cater to the unique needs of startups. Our experienced team combines cutting-edge technology with strategic insights to drive your business forward, ensuring you receive expert guidance, innovative solutions, and dedicated support for achieving your goals.
                  </h2>
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default Middle;
