import React, { useState } from "react";

const ServiceDetailsAbout = () => {
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">What we offer</h3>
                <p><h3>1. Web and Mobile Development</h3>
	<h4>Web Development Services-</h4>
Custom Website Design: Craft visually appealing, user-friendly, and responsive websites tailored to the unique needs of each startup. This includes everything from landing pages to complex web applications.<br/>
E-commerce Solutions: Develop robust e-commerce platforms using tools like Magento, ensuring a seamless shopping experience with secure payment gateways, inventory management, and user-friendly interfaces.<br/>
Content Management Systems (CMS): Implement and customize CMS platforms like WordPress or Drupal, allowing startups to easily manage and update their website content.<br/>
SEO Optimization: Ensure that websites are optimized for search engines, improving their visibility and ranking on search engine results pages (SERPs).

	<h4>Mobile Application Development Services-</h4>
iOS and Android App Development: Design and develop mobile applications for both iOS and Android platforms, ensuring they are user-friendly, feature-rich, and perform well across devices.<br/>
Cross-Platform Development: Utilize frameworks like React Native or Flutter to create apps that work seamlessly on both iOS and Android, reducing development time and cost.<br/>
User Experience (UX) and User Interface (UI) Design: Focus on creating intuitive and engaging app interfaces that provide a positive user experience, enhancing user retention and satisfaction.<br/>
App Testing and Quality Assurance: Conduct thorough testing to ensure that apps are bug-free, secure, and perform well under various conditions.<br/><br/>

<h3>2. E-Commerce Development (Magento Solutions)</h3>
Magento Store Development: Create customized Magento e-commerce stores that are scalable, secure, and designed to meet the specific needs of each startup.<br/>
Magento Integration: Integrate Magento with other systems such as CRM, ERP, payment gateways, and third-party applications to streamline operations and enhance functionality.<br/>
Magento Customization: Tailor Magento’s features and functionalities to fit the unique requirements of each startup, including custom themes, modules, and extensions.<br/>
Performance Optimization: Ensure that Magento stores are optimized for speed and performance, providing a smooth shopping experience for users.<br/><br/>

<h3>3. Market Research</h3>
Industry Analysis: Provide detailed analysis of the industry landscape, including trends, opportunities, and threats. This helps startups understand the market dynamics and position themselves effectively.<br/>
Competitor Analysis: Conduct thorough competitor research to identify key players, their strengths and weaknesses, and market positioning. This information is crucial for startups to develop competitive strategies.<br/>
Customer Insights: Gather and analyze data on target customers, including demographics, preferences, and behavior. This helps startups tailor their products and marketing strategies to meet customer needs.<br/>
Market Entry Strategy: Develop strategic recommendations for market entry, including pricing strategies, distribution channels, and marketing tactics. This ensures startups can effectively launch and grow in their chosen markets.<br/>
Feasibility Studies: Assess the viability of new business ideas or product launches through comprehensive feasibility studies. This includes market demand analysis, financial projections, and risk assessment.<br/><br/>

<h3>4. Startup Consultancy Services</h3>
<h4>Business Planning and Strategy-</h4>
Business Plan Development: Assist startups in crafting comprehensive business plans that include market analysis, business model, financial projections, and operational strategy. This plan serves as a roadmap and is essential for securing investment.
Strategic Planning: Work with startups to define their vision, mission, and long-term goals. Develop strategic plans that outline actionable steps to achieve these goals.
<h4>Legal and Financial Advisory-</h4>
Legal Assistance: Provide guidance on the legal aspects of starting a business, including company formation, intellectual property protection, contract drafting, and compliance with regulations.
Financial Planning and Management: Offer financial consulting services such as budgeting, cash flow management, and financial forecasting. Assist startups in understanding their financial health and planning for sustainable growth.
<h4>Funding and Investment Support-</h4>
Pitch Deck Creation: Help startups create compelling pitch decks that effectively communicate their business idea, market opportunity, and financial projections to potential investors.
Investor Relations: Facilitate connections with angel investors, venture capitalists, and other funding sources. Provide support during investment negotiations and due diligence processes.
Grant and Loan Applications: Assist startups in identifying and applying for relevant grants, loans, and other funding opportunities.<br/><br/>

<h3>5. Mentoring and Training</h3>
<h4>Workshops and Training Programs-</h4>
Entrepreneurship Training: Offer workshops and training programs on essential topics such as lean startup methodology, agile project management, and growth hacking.
Technical Training: Provide training on the latest technologies, development frameworks, and tools relevant to web and app development.
<h4>Mentorship Programs-</h4>
One-on-One Mentoring: Pair startups with experienced mentors who can provide personalized guidance, support, and advice on various aspects of running a business.
Peer Networking: Facilitate networking opportunities with other startups, industry experts, and potential partners to foster collaboration and knowledge sharing.<br/><br/>

<h3>6. Growth and Scaling Support</h3>
<h4>Operational Efficiency-</h4>
Process Optimization: Help startups streamline their operations and improve efficiency through process optimization and the implementation of best practices.<br/>
Technology Integration: Assist in integrating various technological solutions to enhance productivity, such as CRM systems, project management tools, and automation software.<br/><br/>
<h4>Market Expansion-</h4>
International Market Entry: Provide strategic advice and support for startups looking to expand into international markets, including market entry strategies, localization, and regulatory compliance.
Partnership Development: Help identify and establish strategic partnerships and alliances that can drive growth and open new opportunities.
                </p>

                <div className="tp-sv__video">
                  <div
                    className="tp_sv__video-bg"
                    // data-background="/assets/img/service/service-blog-bg.jpg"
                    style={{
                      backgroundImage: `url("/assets/img/service/service-blog-bg.jpg")`,
                    }}
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetailsAbout;
