import React from "react";

const AboutCompany = () => {
  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span>
                  <h2 className="tp-pt-size mb-40">
                    Our business boasts top of the line services
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button>
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                      To be the leading partner for startups, driving their success through innovative web and mobile solutions and insightful market research, and enabling them to turn their ideas into impactful digital realities.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                      Our mission is to empower startups by providing comprehensive services in website and app development, coupled with strategic market research. We aim to transform ideas into functional and aesthetically pleasing digital products while offering the market insights necessary for informed decision-making. By leveraging our expertise and dedication to quality, we help startups navigate the challenges of the digital landscape, ensuring they achieve sustainable growth and competitive advantage.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      
                      Innovation: We are committed to staying at the forefront of technology, continuously exploring new tools, methodologies, and ideas to provide cutting-edge solutions.
<br /> <br />Quality and Excellence:

We strive for the highest standards in all aspects of our work, delivering robust, reliable, and user-friendly digital products that exceed client expectations.
<br /> <br />
Integrity:

We believe in transparency, honesty, and ethical practices in all our interactions. Trust is the foundation of our relationships with clients, partners, and team members.
<br /> <br />
Collaboration:

We value teamwork and foster a collaborative environment where diverse ideas and perspectives are encouraged, leading to better solutions and innovation.
<br /> <br />
Client Empowerment:

We aim to empower our clients with the knowledge and tools they need to succeed, providing not just services but also guidance and support throughout their journey.
<br /> <br />
Adaptability:

We understand the dynamic nature of the startup environment and are flexible in our approach, ready to pivot and adapt to new challenges and opportunities as they arise.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-page.png" alt="" />
                </div>
                <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
