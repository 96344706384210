import React from "react";
import { ServiceItem } from "../../HomeTwo/Services";

const OurService = () => {
  return (
    <>
      <div className="tp-service-ara grey-bg-4 pt-140 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-section-wrapper text-center mb-70">
                <span className="tp-section-subtitle mb-30">OUR SERVICES</span>
                <h2 className="tp-section-title">Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <ServiceItem
              icon="pe-7s-arc"
              title="Web and Mobile Development"
              desc="We design and develop custom websites and mobile applications that are user-friendly and high-performing, tailored to meet the unique needs of startups. Our services include responsive web design, iOS and Android app development, and seamless CMS implementation."
            />
            <ServiceItem
              icon="pe-7s-cloud-download"
              title="E-Commerce Development (Magento Solutions)"
              desc="We create and optimize Magento e-commerce platforms, offering custom development, system integration, and performance enhancements to ensure a secure and efficient online shopping experience. Our solutions are tailored to fit the specific needs of each startup."
            />
            <ServiceItem
              icon="pe-7s-disk"
              title="Market Research"
              desc="We provide in-depth market analysis, competitor research, and customer insights to help startups refine their strategies and make informed decisions. Our services include industry trend analysis, market entry strategies, and feasibility studies to support successful business growth."
            />
            <ServiceItem
              icon="pe-7s-hammer"
              title="Startup Consultancy Services"
              desc="We help startups develop comprehensive business plans and strategies, ensure legal and financial compliance, and secure funding through pitch decks, investor connections, and grant applications."
            />
            <ServiceItem
              icon="pe-7s-umbrella"
              title="Mentoring and Training"
              desc="We offer workshops on essential startup topics and technology trends, along with one-on-one mentoring and networking opportunities to support entrepreneurial growth."
            />
            <ServiceItem
              icon="pe-7s-graph3"
              title="Growth and Scaling Support"
              desc="We optimize business processes for efficiency and assist startups in expanding into new markets with strategic advice, localization, and compliance support."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurService;
