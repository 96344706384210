import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* <!-- footer area start --> */}
      <footer>
        <div className="tp-footer__area black-bg">
          <div className="tp-footer">
            {/* <!-- main-footer start  --> */}
            <div className="tp-footer__main">
              <div className="container">
                <div className="tp-footer-border pt-60 pb-30">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">
                          Our Location
                        </h3>
                        <ul>
                          <li>
                            <a href="#">Birmingham, United Kingdom</a>
                          </li>
                          <li>
                            <a href="#">Jaipur, India</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="tp-footer__widget pb-30">
                        <h3 className="tp-footer__widget-title">Our Service</h3>
                        <ul>
                          <li>
                            <a href="#">Web Development</a>
                          </li>
                          <li>
                            <a href="#">IOS and Android App Development</a>
                          </li>
                          <li>
                            <a href="#">Market Research for Start Ups</a>
                          </li>
                          <li>
                            <a href="#">Salesforce</a>
                          </li>
                          <li>
                            <a href="#">Magento</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 ">
                      <div className="tp-footer__widget  pb-30">
                        <h3 className="tp-footer__widget-title">Quick Links</h3>
                        <ul>
                           <li>
                             <Link to="/about">About us</Link>
                           </li>
                           <li>
                                 <Link to="/service">Services</Link>
                           </li>
                           <li>
                                 <Link to="/contact">contact</Link>
                           </li>
                        </ul>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- main footer end  --> */}
          </div>
        </div>

        {/* <!-- footer copy right --> */}
        <div className="top-footer-copyright pt-30 black-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12">
                <div className="tp-copyrigh-text mb-30">
                  <span>
                    © {new Date().getFullYear()} Bulicano - IT & Business Consulting . All Rights Reserved.
                  </span>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        {/* <!-- footer copyright end  --> */}
      </footer>
      {/* <!-- footer area end --> */}
    </>
  );
};

export default Footer;
