import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import FullScreenBtn from "../common/FullScreenBtn";
import TopAbout from "./TopAbout";
import TopCtaArea from "./TopCtaArea";
import Middle from "./Middle";
import TopFooter from "../common/TopFooter/TopFooter";
import HomeSlider from "./HomeSlider";

const Home = () => {
  return (
    <>
      <Menu />
      <HomeSlider />
      <TopAbout />
      <TopCtaArea />
      <Middle />
      <FullScreenBtn />
      <TopFooter />
      <Footer />
    </>
  );
};

export default Home;
